import React, {useEffect, useRef} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Header from '../components/Index/Header';
import Footer from '../components/App/Footer';
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import RegisterForm from '../components/ProfileAuthentication/RegisterForm';

function Register() {

    const ref = useRef(false);

    // run this function from an event handler or an effect to execute scroll 
    useEffect(() => {
        if(ref.current) {
            setTimeout(() => {
                ref.current.scrollIntoView({ behavior: 'smooth' })
            }, 10)
       }
    }, [])

    return (
        <Layout>
            <Navbar />
            <Header />
            <section className="profile-authentication-area ptb-100" ref={ref} >
                <Container className="">
                    <Row className="">
                        <Col lg={12} className="">
                            <RegisterForm />
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </Layout>
    )
}

export default Register